.custom-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #3b5b92;
  color: #fff;
  text-align: center;
  padding: 10px;
  z-index: 1000;
}

.custom-banner button {
  background: #fff;
  border: none;
  color: #000;
  padding: 5px 10px;
  margin-left: 10px;
}
