.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width:85vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Box shadow for the popup */
  }
  

  .indicator{
    background: #e0dada;
    margin: 10px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;

  }
  .indicator:hover{
    background-color: black;
    color: #e0dada;
  }
  .indicator.selected {
    background-color: black;
    color: #e0dada;
  }

  @mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    position: relative;
    width: $size;
    height: $size;
  
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: ($size - $thickness) / 2;
      left: 0;
      right: 0;
      height: $thickness;
      background: $color;
      border-radius: $thickness;
    }
  
    &:before {
      transform: rotate(45deg);
    }
  
    &:after {
      transform: rotate(-45deg);
    }
  
    span {
      display: block;
    }
  
  }
  
  

  .close-btn {
    margin: 0;
    border: 0;
    padding: 0;
    background: rgb(236, 14, 14);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 150ms;
    
    .icon-cross {
      @include cross(40px, #fff, 6px);   
    }
    
    &:hover,
    &:focus {
      transform: rotateZ(90deg);
      background: hsl(216, 100, 40);
    }
  
  }
  

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; /* Adjust the height as needed to center the spinner vertically */
  }
  .styled-select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #236A80;
    background-color: white;
    width: 40%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    appearance: none; /* Remove default dropdown arrow */
  }
  
  .styled-select:focus {
    border-color: #236A80;
    outline: none;
  }
  
  .styled-select::after {
    content: '\25BC'; /* Custom arrow */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .label-select{
    font-weight: bolder;
  }