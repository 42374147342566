.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width:85vh;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Box shadow for the popup */
  }
  
  .close-btn {
    position: static;
    top: 10px;
    right: 10px;
    background: red;
    border: 2px solid black;
    cursor: pointer;
    margin-right: 15px;

  }
  
  .indicator{
    background: #e0dada;
    margin: 10px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;

  }
  .indicator:hover{
    background-color: black;
    color: #e0dada;
  }
  .indicator.selected {
    background-color: black;
    color: #e0dada;
  }