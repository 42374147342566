.account-details {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .password-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .password-button:hover {
    background-color: #0056b3;
  }
  